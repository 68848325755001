import { afterNextRender, inject, Injectable } from '@angular/core';
import { CartStore, SyncStatus } from './state/cart-store';
import { BNProductVariantIndex } from './models/product';
import { OrderService as OrderServiceApi } from '../openapi/services';
import { Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { OrderShopCartShowSyliusShopCartShow as SyliusCart } from '../openapi/models';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BnOrderService {
  private cartStore = inject(CartStore);
  private orderServiceApi = inject(OrderServiceApi);

  private orderSubscription!: Subscription;

  /**
   * Placheolder, hogy az SSR ne szálljon el, mert nem beszél localStorage -ül.
   * @private
   */
  private browserStorage = {
    load: () => {},
    save: () => {}
  }

  constructor() {
    afterNextRender(() => {
      if (!localStorage) {
        return;
      }

      this.browserStorage = {
        load: () => {
          // betölteni a kosár tokent
          const lsString = localStorage.getItem('bn-cart');
          if (!lsString) {
            this.cartStore.setCartSyncStatus(SyncStatus.NONE);
            return;
          }

          try {
            const cart = JSON.parse(lsString) as SyliusCart;
            if (cart && cart.checkoutState === 'cart') {
              this.cartStore.setCartAndStatus(cart, SyncStatus.BROWSER_HAS_CART);
            }
          } catch (e) {
            console.error('cart load error', e);
            this.cartStore.setCartSyncStatus(SyncStatus.NONE);
          }

          this.initCart();
        },
        save: () => {
          localStorage.setItem('bn-cart', JSON.stringify(this.cartStore.getCart()));
        }
      };

      this.browserStorage.load();
    });
  }

  addToCart(variantCode: BNProductVariantIndex['code'], quantity: number) {
    // ellenőrízzük van-e cartunk, ha nincs, akkor létrehozzuk
    this.orderSubscription = this.createOrLoadOrder()
      .pipe(
        filter(order => !!order),
        // ha sikerült, akkor hozzáadjuk a terméket
        switchMap((order: SyliusCart) => this.addToAPICart(order?.tokenValue!, variantCode, quantity)),
        tap((cart) => {
          this.setLocalStore(cart);
          window.location.href = environment.shopUrl + '/hu_HU/cart/?tokenValue=' + cart.tokenValue;
        })
      )
      .subscribe(this.handleOrderSubscription());
  }

  removeFromCart(variantID: BNProductVariantIndex['id']) {
    this.orderSubscription = this.createOrLoadOrder()
      .pipe(
        filter(order => !!order),
        switchMap((order: SyliusCart) => this.removeFromAPICart(order?.tokenValue!, variantID))
      )
      .subscribe(this.handleOrderSubscription());
  }

  getCartStore() {
    return this.cartStore;
  }


  // ===================================================================================================================

  /**
   * Localstorageba és a Sylius API-ba is szinkronizálja a kosár tartalmát.
   * @private
   */
  private createOrLoadOrder(): Observable<SyliusCart | null> {

    if (this.cartStore.syncStatus() !== SyncStatus.BROWSER_HAS_CART) {
      this.browserStorage.save();
    }

    // innentől a szerverrel kell beszélgetni
    this.cartStore.setLoading(true);

    // már valami folyik, ne indítsunk újabb lekérést
    if (this.orderSubscription && !this.orderSubscription.closed) {
      return of(null);
    }

    // tiszta sor, még nem járt nálunk, kosara sincs
    if (!this.cartStore.hasCart()) {
      return this.createAPIOrder();
    }

    // ellenőrízzük létezik-e a szerveren a kosár
    return this.loadAPIOrder(this.cartStore.getState()?.tokenValue ?? '')
      .pipe(
        switchMap(order => {
          // létezik, de nem cart státuszú, így kérünk egy újat
          if (order.checkoutState !== 'cart') {
            // új kosár kell
            return this.createAPIOrder();
          }
          return of(order);
        })
      );
  }

  private setLocalStore(order: SyliusCart | null) {
    if (!order) {
      this.cartStore.clearCart();
    } else {
      this.cartStore.setCartAndStatus(order, SyncStatus.SYNCED);
    }

    this.browserStorage.save();
  }

  private handleOrderSubscription() {
    return {
      next: (order: SyliusCart | null) => {

      },
      error: (order: any) => {
        if (order.status === 404) {
          // FIXME: nincs ilyen kosár a szerveren, fel kell tölteni az aktuális tartalmat, de jelenleg olyan nincs még
          console.error("Order 404: ", order);
          // ha kell, úgyis nyitunk újat
        }
        this.setLocalStore(null);
        this.cartStore.setLoading(false);
      },
      complete: () => {
        this.cartStore.setLoading(false);
      }
    }
  };

  private initCart() {
    this.orderSubscription = this.createOrLoadOrder()
      .subscribe(this.handleOrderSubscription());
  }


  // rX eljárások az API műveletekhez ==================================================================================
  /**
   * A kosár tartalmát tölti be a szerverről.
   * @param tokenValue a kosár / order saját tokenje
   * @private
   */
  private loadAPIOrder(tokenValue: string) {
    return this.orderServiceApi.shopGetOrderItem$Json({tokenValue: tokenValue});
  }

  private createAPIOrder() {
    // már van kosarunk, vagy éppen egy kosár lekérdezése folyamatban van
    return this.orderServiceApi.shopPostOrderCollection$Json$Json({
        'Accept-Language': 'hu_HU',
        body: {}
      }
    ).pipe(
      tap((order) => {
          this.cartStore.setCartAndStatus(order, SyncStatus.SYNCED);
          this.browserStorage.save();
        }
      )
    );
  }

  /**
   *
   * @param orderToken
   * @param variantCode @see BNProductVariantIndex['code']
   * @param quantity
   * @private
   */
  private addToAPICart(orderToken: string, variantCode: BNProductVariantIndex['code'], quantity: number) {
    return this.orderServiceApi.shopAddItemOrderItem$Html$Json({
        tokenValue: orderToken,
        body: {
          productVariant: variantCode,
          quantity: quantity
        }
      }
    );
  }

  /**
   * Törli a megadott terméket a kosárból.
   * @param orderToken
   * @param variantID Variant.id => number annak elllenére, hogy string
   * @private
   */
  private removeFromAPICart(orderToken: string, variantID: BNProductVariantIndex['id']) {
    return this.orderServiceApi.shopRemoveItemOrderItem({
        tokenValue: orderToken,
        itemId: variantID
      }
    ).pipe(
      switchMap(() => this.loadAPIOrder(orderToken))
    );
  }
}
