import { getState, patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { OrderShopCartShowSyliusShopCartShow as SyliusCart } from '../../openapi/models';
import { computed } from '@angular/core';
import {
  Show as AddressShopCartShowSyliusShopCartShow
} from '../../openapi/models/Address-shop/cart/show_sylius/shop/cart/show';


/*
  * Az aktuális szinkronizációs státusz (szerver-böngésző tárolók).
  * // FIxme: megnézni van-e erre egyátalán szükség
 */
export enum SyncStatus {
  /**
   * Nincs kosár sem lokálisan, sem szerveren. Ha jön be elem, létre kell hozni.
   */
  NONE,
  /**
   * Böngészőben van kosár és elemek, de nem ismert a szerver állapot
   */
  BROWSER_HAS_CART,
  /**
   * A böngészőben van kosár, de a szerveren már nem (a böngészőben ha vannak benne elemek, szerveren létre kell hozni)
   */
  BROWSER_ONLY,
  /**
   * Mindkét helyen ismert a kosár, de nem egyezik a tartalom (eltérnek az elemek vagy az árak)
   */
  NOT_SYNCED,
  /**
   * Mindkét helyen ismert a kosár, és a tartalom is egyezik
   */
  SYNCED
}

export interface BNSyliusCartWithSyncState extends Required<SyliusCart> {
  syncStatus: SyncStatus;
  loading: boolean;
  billingAddress: AddressShopCartShowSyliusShopCartShow | null;
}


export const CartStore = signalStore(
  {providedIn: 'root'},
  withState<BNSyliusCartWithSyncState>({
    syncStatus: SyncStatus.NONE,
    loading: false,
    billingAddress: null,
    checkoutState: '',
    currencyCode: '',
    id: 0,
    items: [],
    itemsSubtotal: 0,
    itemsTotal: 0,
    localeCode: '',
    number: '',
    orderPromotionTotal: 0,
    paymentState: '',
    payments: [],
    shipments: [],
    shippingAddress: null,
    shippingPromotionTotal: 0,
    shippingState: '',
    shippingTaxTotal: 0,
    shippingTotal: 0,
    taxExcludedTotal: 0,
    taxIncludedTotal: 0,
    taxTotal: 0,
    tokenValue: '',
    total: 0
  }),
  withComputed((store) => ({
    cartState: computed(() => store),
    cartTotal: computed(() => store && store?.total ? store.total() : 0),
    cartCurrency: computed(() => store && store?.currencyCode ? store.currencyCode() : 'HUF')
  })),
  withMethods((store) => ({
    setLoading: (loading: boolean) => patchState(store, {loading}),
    setCart: (cart: SyliusCart) => patchState(store, cart),
    setCartAndStatus: (cart: SyliusCart, syncStatus: SyncStatus, loading: boolean = false) => patchState(store, {
      syncStatus,
      loading, ...cart
    }),
    setCartSyncStatus: (syncStatus: SyncStatus, loading: boolean = false) => patchState(store, {loading, syncStatus}),
    getCart: (): SyliusCart => {
      const {syncStatus, loading, ...state} = getState(store);
      return state;
    },
    hasCart: () => !!store.tokenValue(),
    getState: () => getState(store),
    clearCart: () => patchState(store, {
      syncStatus: SyncStatus.NONE,
      loading: false,
      billingAddress: null,
      checkoutState: '',
      currencyCode: '',
      id: 0,
      items: [],
      itemsSubtotal: 0,
      itemsTotal: 0,
      localeCode: '',
      number: '',
      orderPromotionTotal: 0,
      paymentState: '',
      payments: [],
      shipments: [],
      shippingAddress: null,
      shippingPromotionTotal: 0,
      shippingState: '',
      shippingTaxTotal: 0,
      shippingTotal: 0,
      taxExcludedTotal: 0,
      taxIncludedTotal: 0,
      taxTotal: 0,
      tokenValue: '',
      total: 0
    })
  }))// withMethods

); // CartStore

export type CartStoreType = InstanceType<typeof CartStore>;
